<template>
    <div class="form-group">
        <label :for="propertyDefinition.name">{{ propertyDefinition.label }}</label>
        <input type="number"
               class="form-control"
               :class="{ 'is-invalid': $v.value.$error }"
               :id="propertyDefinition.name"
               :name="propertyDefinition.name"
               v-model="value"
               :readonly="propertyDefinition.readOnly || propertyDefinition.computed"
               :min="this.minValueNumber"
               :max="this.maxValueNumber"
               @blur="onBlurAutoCorrect"
               autocomplete="disabled"/>
        <small class="form-text text-muted" v-if="propertyDefinition.description">{{ propertyDefinition.description }}</small>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { integer, decimal, minValue, maxValue } from 'vuelidate/lib/validators';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

/**
 * Property Definition - Number (Long and Double)
 *
 * NOTE_INFINITE_LOOP:
 * By assigning a new value to 'this.value'
 * we expect that watcher will triggered again.
 * We new value must be valid.
 * If it's not valid an infinite loop can be occurred.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 * @lastModifiedAt 1 August 2020
 */
export default {
    name: 'PdNumber',
    mixins: [
        validationMixin
    ],
    props: {
        propertyDefinition: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            minValueNumber: null,
            maxValueNumber: null,

            value: null
        };
    },
    validations () {
        const extraValidations = {}; // to merge with hard-coded validations.
        if (typeof this.minValueNumber === 'number') extraValidations.minValue = minValue(this.minValueNumber);
        if (typeof this.maxValueNumber === 'number') extraValidations.maxValue = maxValue(this.maxValueNumber);

        if (this.propertyDefinition.type === 'LONG') {
            return {
                value: {
                    integer,
                    ...extraValidations
                }
            };
        } else if (this.propertyDefinition.type === 'DOUBLE') {
            return {
                value: {
                    decimal,
                    ...extraValidations
                }
            };
        } else {
            // Unreachable.
            return {
                value: {
                    ...extraValidations
                }
            };
        }
    },
    beforeMount () {
        // Get necessary information for form and control.
        this.minValueNumber = get(this.propertyDefinition, 'validations.minValue', null);
        this.maxValueNumber = get(this.propertyDefinition, 'validations.maxValue', null);

        // Validate validators.
        if (typeof this.minValueNumber === 'number' && typeof this.maxValueNumber === 'number') {
            if (this.minValueNumber >= this.maxValueNumber) {
                // @future 2020-08-01 Here? Reset? Leave it as it is.
            }
        }

        // Set for the 1st time.
        this.value = this.$store.getters['personEdit/property'](this.propertyDefinition.name).workingValue;

        // The listen to changes and use mutations to modify property.
        this.$watch('value', {
            handler: debounce(function (ignoredValue) {
                let newWorkingValue = this.getNewWorkingValue();

                this.$v.value.$touch();
                const baseValue = this.$store.getters['personEdit/property'](this.propertyDefinition.name).baseValue;
                const changed = newWorkingValue !== baseValue;
                this.$store.commit('personEdit/modifyProperty', {
                    name: this.propertyDefinition.name,
                    value: newWorkingValue,
                    error: this.$v.value.$error,
                    valid: !this.$v.value.$invalid,
                    changed: changed
                });
                this.$store.commit('personEdit/syncPropertiesCounts');
            }, 250),
            deep: true
        });
    },
    methods: {
        getNewWorkingValue () {
            let newWorkingValue = 0;
            if (this.propertyDefinition.type === 'LONG') {
                newWorkingValue = parseInt(this.$v.value.$model, 10);
            } else if (this.propertyDefinition.type === 'DOUBLE') {
                newWorkingValue = parseFloat(this.$v.value.$model);
            } else {} // Unreachable.
            return newWorkingValue;
        },

        onBlurAutoCorrect () {
            let newWorkingValue = this.getNewWorkingValue();

            // Check if invalid value was provided.
            if (typeof newWorkingValue !== 'number' || isNaN(newWorkingValue)) {
                newWorkingValue = 0;
                // @help read comment about NOTE_INFINITE_LOOP.
            }

            // min-max validations.
            // 2020-08-01 Testing validation and auto-correction onBlur.
            if (typeof this.minValueNumber === 'number') {
                if (newWorkingValue < this.minValueNumber) {
                    // @help read comment about NOTE_INFINITE_LOOP.
                    newWorkingValue = this.minValueNumber;
                }
            }
            if (typeof this.maxValueNumber === 'number') {
                if (newWorkingValue > this.maxValueNumber) {
                    // @help read comment about NOTE_INFINITE_LOOP.
                    newWorkingValue = this.maxValueNumber;
                }
            }

            this.value = newWorkingValue;
        }
    }
};
</script>
