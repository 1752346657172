<template>
    <div class="form-group form-check">
        <input type="checkbox"
               class="form-check-input"
               :id="propertyDefinition.name"
               :name="propertyDefinition.name"
               v-model="value"
               :true-value="true"
               :false-value="false"
               :readonly="propertyDefinition.readOnly || propertyDefinition.computed"
               autocomplete="disabled"/>
        <label class="form-check-label " :for="propertyDefinition.name">{{ propertyDefinition.label }}</label>
        <small class="form-text text-muted" v-if="propertyDefinition.description">{{ propertyDefinition.description }}</small>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import debounce from 'lodash/debounce';

/**
 * Property Definition - Boolean
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 * @lastModifiedAt 11 July 2020
 */
export default {
    name: 'PdBoolean',
    mixins: [
        validationMixin
    ],
    props: {
        propertyDefinition: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            value: null
        };
    },
    validations: {
        value: {}
    },
    beforeMount () {
        // Set for the 1st time.
        this.value = this.$store.getters['personEdit/property'](this.propertyDefinition.name).workingValue;

        // The listen to changes and use mutations to modify property.
        this.$watch('value', {
            handler: debounce(function (ignoredValue) {
                this.$v.value.$touch();
                const newWorkingValue = this.$v.value.$model;
                const baseValue = this.$store.getters['personEdit/property'](this.propertyDefinition.name).baseValue;
                const changed = newWorkingValue !== baseValue;
                this.$store.commit('personEdit/modifyProperty', {
                    name: this.propertyDefinition.name,
                    value: newWorkingValue,
                    error: this.$v.value.$error,
                    valid: !this.$v.value.$invalid,
                    changed: changed
                });
                this.$store.commit('personEdit/syncPropertiesCounts');
            }, 250),
            deep: true
        });
    }
};
</script>
