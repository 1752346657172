<template>
    <div class="PersonEditHeader bg-white border-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="py-3 px-0">
                        <p class="mb-0 weight-7 size-6 line-height-2" v-if="isCreate" key="new-person-title">{{ $t('New Person') }}</p>
                        <p class="mb-0 weight-7 size-6 line-height-2" v-else key="edit-person-title">
                            {{ $t('Edit Person') }} / <router-link :to="{ name: 'person' }">{{ personName | ellipsis(34) }}</router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';

/**
 * PersonEditHeader : Person Edit header.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 */
export default {
    name: 'PersonEditHeader',
    computed: {
        ...mapGetters('personEdit', {
            person: 'person'
        }),
        isCreate () {
            return get(this.person, 'id', null) === null;
        },
        personName () {
            return get(this.person, 'properties.name', null);
        }
    }
};
</script>

<i18n>
{
    "en": {
        "New Person": "New Person",
        "Edit Person": "Edit Person"
    },
    "el": {
        "New Person": "Νέο Άτομο",
        "Edit Person": "Επεξεργασία Ατόμου"
    }
}
</i18n>
