<template>
    <div class="PersonEditGdpr" v-if="displayGdprActions">
        <div class="alert alert-warning C-Alert">
            <b-form-checkbox id="gdprActionCheckbox"
                             name="gdprActionCheckbox"
                             v-model="value"
                             :value="true"
                             :unchecked-value="false">
                {{ $t('person_create_edit_gdpr_statement_checkbox') }}
            </b-form-checkbox>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

const PROPERTY_DEFINITION_NAME = 'lawfulBasisExists';

/**
 * Actions related to GDPR.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 11 July 2020
 */
export default {
    name: 'PersonEditGdpr',
    computed: {
        ...mapGetters('personEdit', {
            propertyLawfulBasisExistsWorkingValue: 'propertyLawfulBasisExistsWorkingValue',
            displayGdprActions: 'displayGdprActions'
        }),
        value: {
            get () {
                return this.propertyLawfulBasisExistsWorkingValue;
            },
            set (newWorkingValue) {
                if (typeof newWorkingValue !== 'boolean') newWorkingValue = false;
                const baseValue = this.$store.getters['personEdit/property'](PROPERTY_DEFINITION_NAME).baseValue;
                const changed = newWorkingValue !== baseValue;
                this.$store.commit('personEdit/modifyProperty', {
                    name: PROPERTY_DEFINITION_NAME,
                    value: newWorkingValue,
                    error: false,
                    valid: true,
                    changed: changed
                });
                this.$store.commit('personEdit/syncPropertiesCounts');
                this.$store.commit('personEdit/updatePropertyGroupReactivityId', 'legal');
            }
        }
    }
};
</script>
