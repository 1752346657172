<template>
    <div class="PersonEditView ViewWrapper LwHaB">
        <clv-head-meta :title="[
            stateIsChanging === true ? $t('common_state_loading') + '...' : null,
            personName,
            $t('New Person'),
            $t('Not Found')
        ]"></clv-head-meta>

        <!-- VIEW LOADER -->
        <element-loading :active="stateIsChanging" :is-full-view="true" :lock-parent="true"></element-loading>

        <!-- EMPTY STATE 2020-07-19 -->
        <simple-state-not-found :for-view="true" v-if="displayEmptyState">
            <div class="mt-2" slot="action">
                <router-link tag="a" :to="{ name: 'people' }"><i class="fas fa-chevron-left"></i> {{ $t('Browse People') }}</router-link>
            </div>
        </simple-state-not-found>

        <!-- @future add error state too? In case that SchemaDefinition is null -->

        <!-- MAIN -->
        <div class="d-md-flex flex-md-column LwHaB-Main Disable-md" v-if="displayForm">
            <person-edit-header></person-edit-header>
            <div class="flex-grow-1 flex-shrink-1 LwHaB-Main__Body WithMultipleColumns DisableMultipleColumns-sm">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-8 col-lg-9">
                            <div id="property-groups-listing"
                                 ref="content"
                                 class="LwHaB-Main__Body__Column WithMarginTop disable-sm CustomScrollbar--Default bg-white border">
                                <div class="FormDefault">
                                    <!-- PROPERTY GROUPS LISTING -->
                                    <property-group-and-pd-router v-for="propertyGroup in propertyGroups"
                                                                  :property-group="propertyGroup"
                                                                  :key="propertyGroup.rId"></property-group-and-pd-router>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3">
                            <div class="LwHaB-Main__Body__Column WithMarginTop disable-sm WithMarginBottom-sm CustomScrollbar--Default">
                                <div class="PersonEditNav d-none d-md-block">
                                    <nav class="nav nav-pills flex-column">
                                        <a class="text-left nav-link rounded-0 border-0 weight-5 text-microcopy--90"
                                           v-for="propertyGroupBasics in propertyGroupsBasics"
                                           :key="propertyGroupBasics.name"
                                           :href="'#' + propertyGroupBasics.name"
                                           @click="scrollIntoView($event, propertyGroupBasics.name)">
                                            {{ propertyGroupBasics.label }}
                                        </a>
                                    </nav>
                                </div>
                                <hr/>
                                <!-- MESSAGES / FORM VALIDATION -->
                                <div class="alert alert-danger C-Alert" v-if="propertiesInvalidCount">
                                    {{ $t('Form contains invalid fields') }}
                                </div>
                                <!-- GDPR -->
                                <person-edit-gdpr></person-edit-gdpr>
                                <!-- ACTIONS -->
                                <button class="btn btn-primary btn-block" @click="submitAndSave('view')" :disabled="disableSave">
                                    {{ isCreate ? $t('Create') : $t('Save') }}
                                </button>
                                <button class="btn btn-link btn-block text-left" @click="submitAndSave('edit')" :disabled="disableSave">
                                    {{ isCreate ? $t('Create and continue editing') : $t('Save and continue editing') }}
                                </button>
                                <button class="btn btn-link btn-block text-left" @click="submitAndSave('new')" :disabled="disableSave">
                                    {{ isCreate ? $t('Create and add another') : $t('Save and add another') }}
                                </button>
                                <person-edit-delete v-if="isCreate === false"></person-edit-delete>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import RouterUtils from '@/common/utils/router.utils';
import PersonEditHeader from '@/modules/PersonEdit/views/PersonEditHeader';
import PropertyGroupAndPdRouter from '@/modules/PersonEdit/form/PropertyGroupAndPdRouter';
import PersonEditGdpr from '@/modules/PersonEdit/views/PersonEditGdpr';
import PersonEditDelete from '@/modules/PersonEdit/views/PersonEditDelete';

/**
 * Person create/edit view.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 * @lastModifiedAt 16 March 2021
 */
export default {
    name: 'PersonEditView',
    components: {
        PersonEditHeader,
        PropertyGroupAndPdRouter,
        PersonEditGdpr,
        PersonEditDelete
    },
    computed: {
        ...mapGetters('personEdit', {
            stateIsChanging: 'stateIsChanging',
            displayEmptyState: 'displayEmptyState',
            displayForm: 'displayForm',
            disableSave: 'disableSave',
            propertyGroups: 'propertyGroups',
            propertyGroupsBasics: 'propertyGroupsBasics',
            propertiesInvalidCount: 'propertiesInvalidCount',
            person: 'person'
        }),
        isCreate () {
            return get(this.person, 'id', null) === null;
        },
        personName () {
            const n = get(this.person, 'properties.name', null);
            if (n === null || n === '') return null;
            return n;
        }
    },
    beforeMount () {
        this.$store.commit('personEdit/setGdprAddOnEnabled', window.cs3w.get('gdprAddOnEnabled', false));
        // The listen to changes and use mutations to modify property.
        this.$watch('$route', {
            handler: function (to, from) {
                const result = RouterUtils.resourceIdHelper(to, from);

                // It's important to set 'personId'. It can be null if action is 'create'.
                this.$store.commit('personEdit/setPersonId', result.toId);

                // On route change, initialize.
                if (result.mustInitialize) {
                    this.$store.dispatch('personEdit/resetModule').then(() => {
                        // It's important to set 'personId'. It can be null if action is 'create'.
                        this.$store.commit('personEdit/setPersonId', result.toId);

                        // Initialize store module..
                        this.$store.dispatch('personEdit/initializeModule').then(() => void 0).catch(() => void 0);
                    }).catch(() => void 0);
                }
            },
            immediate: true
        });
    },
    beforeDestroy () {
        // Reset store.
        // this.$store.dispatch('personEdit/resetModule').then(() => void 0).catch(() => void 0);
        // Check youtrack issue.
    },
    methods: {
        // Convenience method to scroll a heading into view.
        scrollIntoView (evt, name) {
            evt.preventDefault();
            // Force open the div.
            this.$store.commit('personEdit/ensurePropertyGroupUiOpened', name);
            // Then, go to div.
            setTimeout(function () {
                const href = evt.target.getAttribute('href');
                const el = href ? document.querySelector(href) : null;
                if (el) {
                    this.$refs.content.scrollTop = el.offsetTop;
                }
            }.bind(this), 150);
        },

        submitAndSave (redirectType = 'view') {
            this.$store.dispatch('personEdit/validationsBeforeSave')
                .then((ignoredResults) => {
                    this.save(redirectType);
                })
                .catch((results) => {
                    if (results.identifyingInformationMissing) {
                        this.toastError('person_creator_validation_at_least_one_identifying');
                    } else {} // Unreachable.
                });
        },
        save (redirectType = 'view') {
            // Person the save operation.
            // It will automatically perform the right operation (create/update).
            // noinspection JSUnusedLocalSymbols
            this.$store.dispatch('personEdit/save')
                .then((result) => {
                    if (result.error !== null) {
                        // @future TEMPORARY Solution.
                        // Back-end: send email/phoneNumber and ID. Check if it's valid. And then check if it's unique.
                        let dataDetail = ''; // dataDetail can be missing.
                        if (typeof result.error.dataDetail === 'string') {
                            dataDetail = result.error.dataDetail;
                        }
                        if (dataDetail.startsWith('Person with email ')) {
                            const message = this.$t('temp_email_not_unique', {
                                email: this.$store.getters['personEdit/property']('email')['workingValue']
                            });
                            this.toastError(message, false);
                        } else if (dataDetail.startsWith('Person with phoneNumber ')) {
                            const message = this.$t('temp_phoneNumber_not_unique', {
                                phoneNumber: this.$store.getters['personEdit/property']('phoneNumber')['workingValue']
                            });
                            this.toastError(message, false);
                        } else {
                            this.toastErrorFromError(result.error);
                        }
                    } else {
                        // Replace route.
                        this.$router.push({
                            name: 'person-edit-redirect',
                            params: {
                                type: redirectType,
                                id: result.person.id
                            }
                        });
                    }
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                });
        }
    }
};
</script>

<style lang="scss">
    .PersonEditView {

        .LwHaB-Main__Body__Column {
            // 60px the app nav
            // 59px the inner header
            // 30px the desired total margin (top and bottom)
            min-height: calc(100vh - 60px - 59px - 30px);
            max-height: calc(100vh - 60px - 59px - 30px);
        }
    }
</style>

<i18n>
{
    "en": {
        "New Person": "New Person",
        "Edit Person": "Edit Person",
        "Form contains invalid fields": "Form contains invalid fields",
        "Create": "Create",
        "Save": "Save",
        "Delete": "Delete",
        "Create and add another": "Create and add another",
        "Create and continue editing": "Create and continue editing",
        "Save and add another": "Save and add another",
        "Save and continue editing": "Save and continue editing",
        "temp_email_not_unique": "Email {email} is already assigned to another Person",
        "temp_phoneNumber_not_unique": "Phone Number {phoneNumber} is already assigned to another Person"
    },
    "el": {
        "New Person": "Νέο Άτομο",
        "Edit Person": "Επεξεργασία Ατόμου",
        "Form contains invalid fields": "Η φόρμα εμπεριέχει μη έγκυρες τιμές",
        "Create": "Δημιουργία",
        "Save": "Αποθήκευση",
        "Delete": "Διαγραφή",
        "Create and add another": "Δημιουργία και προσθήκη νέου",
        "Create and continue editing": "Δημιουργία και συνέχιση επεξεργασίας",
        "Save and add another": "Αποθήκευση και προσθήκη νέου",
        "Save and continue editing": "Αποθήκευση και συνέχιση επεξεργασίας",
        "temp_email_not_unique": "Το email {email} έχει ήδη ανατεθεί σε άλλο Άτομο",
        "temp_phoneNumber_not_unique": "Ο αριθμός τηλεφώνου {phoneNumber} έχει ήδη ανατεθεί σε άλλο Άτομο"
    }
}
</i18n>
