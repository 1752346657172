<template>
    <div class="form-group">
        <label :for="propertyDefinition.name">{{ propertyDefinition.label }}</label>
        <select class="form-control"
               :class="{ 'is-invalid': $v.value.$error }"
               :id="propertyDefinition.name"
               :name="propertyDefinition.name"
               v-model="value"
               :readonly="propertyDefinition.readOnly || propertyDefinition.computed"
                autocomplete="disabled">
            <option v-for="option in propertyDefinition.enumerationValuesOptions"
                    :key="option.value"
                    :value="option.value">{{ option.label }}</option>
        </select>
        <small class="form-text text-muted" v-if="propertyDefinition.description">{{ propertyDefinition.description }}</small>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import debounce from 'lodash/debounce';

/**
 * Property Definition - Enumeration
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 * @lastModifiedAt 11 July 2020
 */
export default {
    name: 'PdEnumeration',
    mixins: [
        validationMixin
    ],
    props: {
        propertyDefinition: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            value: null
        };
    },
    validations: {
        value: {
            required
        }
    },
    beforeMount () {
        // Set for the 1st time.
        this.value = this.$store.getters['personEdit/property'](this.propertyDefinition.name).workingValue;

        // The listen to changes and use mutations to modify property.
        this.$watch('value', {
            handler: debounce(function (ignoredValue) {
                this.$v.value.$touch();
                const newWorkingValue = this.$v.value.$model;
                const baseValue = this.$store.getters['personEdit/property'](this.propertyDefinition.name).baseValue;
                const changed = newWorkingValue !== baseValue;
                this.$store.commit('personEdit/modifyProperty', {
                    name: this.propertyDefinition.name,
                    value: newWorkingValue,
                    error: this.$v.value.$error,
                    valid: !this.$v.value.$invalid,
                    changed: changed
                });
                this.$store.commit('personEdit/syncPropertiesCounts');
            }, 250),
            deep: true
        });
    }
};
</script>
