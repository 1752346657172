<template>
    <div class="form-group">
        <label :for="propertyDefinition.name">{{ propertyDefinition.label }}</label>
        <input type="date"
               pattern="\d{4}-\d{2}-\d{2}"
               class="form-control"
               :class="{ 'is-invalid': $v.value.$error }"
               :id="propertyDefinition.name"
               :name="propertyDefinition.name"
               v-model="value"
               :readonly="propertyDefinition.readOnly || propertyDefinition.computed"
               autocomplete="disabled"/>
        <small class="form-text text-muted" v-if="propertyDefinition.description">{{ propertyDefinition.description }}</small>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import debounce from 'lodash/debounce';

function isValidDate (d) {
    return d instanceof Date && !isNaN(d);
}

function dateToStringForForm (date) {
    if (!isValidDate(date)) return null;
    let year = date.getFullYear(); // Number
    let month = date.getMonth() + 1; // Number (+1 month because starts from zero)
    let day = date.getDate();

    year = year + '';
    month = (month >= 0 && month <= 9 ? ('0' + month) : month) + '';
    day = (day >= 0 && day <= 9 ? ('0' + day) : day) + '';

    return year + '-' + month + '-' + day;
}

function stringOrDateToDate (str, withTimeReset = false) {
    // If it's already date, return value.
    if (isValidDate(str)) return str;

    if (typeof str !== 'string') return null;
    if (str.trim() === '') return null;

    let date = null;
    try {
        date = new Date(str);
    } catch (e) {
        return null;
    }

    if (!isValidDate(date)) return null;

    if (withTimeReset === true) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
    }

    return date;
}

// assumes 2 valid JS Date objects
// if both of them are null, returns true.
function areDatesEqual (d1, d2) {
    if (d1 === null && d2 === null) return true;
    if (!isValidDate(d1) || !isValidDate(d2)) return false;
    return d1.getTime() === d2.getTime();
}

/**
 * Property Definition - Instant (aka Date and Date Time)
 *
 * @future Another option is to create select boxes with day, month and year.
 *         That way we will have more control over data transformation/conversion.
 * @future We have to warn user somehow if any date conversion fails.
 * @future Replace with widget (simple to use but yet beautiful)
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#Browser_compatibility
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#Handling_browser_support
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 * @lastModifiedAt 11 July 2020
 */
export default {
    name: 'PdInstant',
    mixins: [
        validationMixin
    ],
    props: {
        propertyDefinition: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            value: null
        };
    },
    validations: {
        value: {}
    },
    beforeMount () {
        this.autoCorrect(); // Make sure that base and working values are right and valid.

        // Set for the 1st time.
        const workingValue = this.$store.getters['personEdit/property'](this.propertyDefinition.name).workingValue;
        const dateValue = stringOrDateToDate(workingValue, true);
        const formValue = dateToStringForForm(dateValue);
        if (formValue === null) {
            this.value = ''; // Required for form. Cannot set to null.
            this.$store.commit('personEdit/modifyPropertyWorkingValue', {
                name: this.propertyDefinition.name,
                value: null
            });
        } else {
            this.value = formValue; // Expected date in string format.
            this.$store.commit('personEdit/modifyPropertyWorkingValue', {
                name: this.propertyDefinition.name,
                value: dateValue
            });
        }

        // The listen to changes and use mutations to modify property.
        this.$watch('value', {
            handler: debounce(function (ignoredValue) {
                this.$v.value.$touch();

                const formValue = this.$v.value.$model;
                const newWorkingValue = stringOrDateToDate(formValue);
                // We 've set the date before...
                const baseValue = this.$store.getters['personEdit/property'](this.propertyDefinition.name).baseValue;
                // formValue cannot be null, only empty string. Form control ensures that.
                const validCustom = formValue === '' ? true : isValidDate(newWorkingValue);
                const valid = !this.$v.value.$invalid && validCustom;
                const changed = !areDatesEqual(newWorkingValue, baseValue);

                this.$store.commit('personEdit/modifyProperty', {
                    name: this.propertyDefinition.name,
                    value: newWorkingValue,
                    error: this.$v.value.$error,
                    valid: valid,
                    changed: changed
                });
                this.$store.commit('personEdit/syncPropertiesCounts');
            }, 250),
            deep: true
        });
    },
    methods: {
        autoCorrect () {
            if (this.$store.getters['personEdit/hasBeenAutoCorrectedV0'](this.propertyDefinition.name)) return;

            const storeValue = this.$store.getters['personEdit/property'](this.propertyDefinition.name).baseValue;
            const dateValue = stringOrDateToDate(storeValue, true);
            const formValue = dateToStringForForm(dateValue);
            if (formValue === null) {
                this.value = ''; // Required for form. Cannot set to null.
                // @future : Warn user? Send crash report? What happens if propertyDefinition is not nullable.
                this.$store.commit('personEdit/modifyPropertyBaseValue', {
                    name: this.propertyDefinition.name,
                    value: null
                });
                this.$store.commit('personEdit/modifyPropertyWorkingValue', {
                    name: this.propertyDefinition.name,
                    value: null
                });
            } else {
                this.value = formValue; // Expected date in string format.
                this.$store.commit('personEdit/modifyPropertyBaseValue', {
                    name: this.propertyDefinition.name,
                    value: dateValue
                });
                this.$store.commit('personEdit/modifyPropertyWorkingValue', {
                    name: this.propertyDefinition.name,
                    value: dateValue
                });
            }

            this.$store.commit('personEdit/setAutoCorrectionStatusToTrueV0ForProperty', this.propertyDefinition.name);
        }
    }
};
</script>
