<template>
    <div class="form-group">
        <label :for="propertyDefinition.name">{{ propertyDefinition.label }}</label>
        <input type="text"
               class="form-control"
               :class="{ 'is-invalid': $v.value.$error }"
               :id="propertyDefinition.name"
               :name="propertyDefinition.name"
               v-model="value"
               :readonly="propertyDefinition.readOnly || propertyDefinition.computed"
               autocomplete="disabled"
               v-if="control === 'input'"
               key="pd-string-input-control"/>
        <textarea class="form-control"
                  :class="{ 'is-invalid': $v.value.$error }"
                  :id="propertyDefinition.name"
                  :name="propertyDefinition.name"
                  v-model="value"
                  :readonly="propertyDefinition.readOnly || propertyDefinition.computed"
                  autocomplete="disabled"
                  v-else-if="control === 'textarea'"
                  key="pd-string-textarea-control"></textarea>
        <small class="form-text text-muted" v-if="propertyDefinition.description">{{ propertyDefinition.description }}</small>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { maxLength } from 'vuelidate/lib/validators';
import debounce from 'lodash/debounce';

/**
 * Property Definition - String
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 * @lastModifiedAt 16 March 2021
 */
export default {
    name: 'PdString',
    mixins: [
        validationMixin
    ],
    props: {
        propertyDefinition: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            control: null,
            value: null
        };
    },
    validations () {
        const maxLengthValue = this.propertyDefinition.sourceSpecifics.specifics.bigString === true ? 1000 : 255;
        return {
            value: {
                maxLength: maxLength(maxLengthValue)
            }
        };
    },
    beforeMount () {
        this.control = this.propertyDefinition.sourceSpecifics.specifics.bigString === true ? 'textarea' : 'input';

        // Set for the 1st time.
        this.value = this.$store.getters['personEdit/property'](this.propertyDefinition.name).workingValue;

        // Then listen to changes and use mutations to modify property.
        this.$watch('value', {
            handler: debounce(function (ignoredValue) {
                this.$v.value.$touch();
                const newWorkingValue = this.$v.value.$model;
                const baseValue = this.$store.getters['personEdit/property'](this.propertyDefinition.name).baseValue;
                const changed = newWorkingValue !== baseValue;
                this.$store.commit('personEdit/modifyProperty', {
                    name: this.propertyDefinition.name,
                    value: newWorkingValue,
                    error: this.$v.value.$error,
                    valid: !this.$v.value.$invalid,
                    changed: changed
                });
                this.$store.commit('personEdit/syncPropertiesCounts');
            }, 250),
            deep: true
        });
    }
};
</script>
