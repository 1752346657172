<template>
    <df-property-group :can-be-closed="propertyGroupUiCanBeClosed(propertyGroup.name)"
                       :opened="propertyGroupUiOpened(propertyGroup.name)"
                       :name="propertyGroup.name"
                       :label="propertyGroup.label"
                       :description="propertyGroup.description"
                       v-on:toggle="togglePropertyGroup">
        <div class="py-3 px-4" slot="header">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <p class="mb-0 weight-5 size-6">{{ propertyGroup.label }}</p>
                    <p class="mb-0 text-microcopy--75 text-muted" v-if="propertyGroup.description">{{ propertyGroup.description }}</p>
                </div>
                <div v-if="propertyGroupUiCanBeClosed(propertyGroup.name)">
                    <p class="mb-0 cursor-pointer user-select-none" @click="togglePropertyGroup">
                        <i class="far fa-window-minimize" v-if="propertyGroupUiOpened(propertyGroup.name)" key="icon-for-opened"></i>
                        <i class="fas fa-plus" v-else key="icon-for-closed"></i>
                    </p>
                </div>
            </div>
        </div>
        <div class="py-4 px-4" slot="body">
            <div v-for="propertyDefinition in propertyGroup.propertyDefinitions"
                 :key="propertyDefinition.name">
                <pd-string :property-definition="propertyDefinition"
                           v-if="propertyDefinition.type === 'STRING' && propertyDefinition.list === false"
                           :key="'pd-string-' + propertyDefinition.name"></pd-string>
                <pd-list-string :property-definition="propertyDefinition"
                                v-if="propertyDefinition.type === 'STRING' && propertyDefinition.list === true"
                                :key="'pd-list-string-' + propertyDefinition.name"></pd-list-string>
                <pd-boolean :property-definition="propertyDefinition"
                            v-else-if="propertyDefinition.type === 'BOOLEAN'"
                            :key="'pd-boolean-' + propertyDefinition.name"></pd-boolean>
                <pd-number :property-definition="propertyDefinition"
                           v-else-if="propertyDefinition.type === 'LONG'"
                           :key="'pd-long-' + propertyDefinition.name"></pd-number>
                <pd-number :property-definition="propertyDefinition"
                           v-else-if="propertyDefinition.type === 'DOUBLE'"
                           :key="'pd-double-' + propertyDefinition.name"></pd-number>
                <pd-instant :property-definition="propertyDefinition"
                            v-else-if="propertyDefinition.type === 'INSTANT'"
                            :key="'pd-instant-' + propertyDefinition.name"></pd-instant>
                <pd-enumeration :property-definition="propertyDefinition"
                                v-else-if="propertyDefinition.type === 'ENUMERATION'"
                                :key="'pd-enumeration-' + propertyDefinition.name"></pd-enumeration>
            </div>
        </div>
    </df-property-group>
</template>

<script>
import { mapGetters } from 'vuex';
import PdString from '@/modules/PersonEdit/form/PdString';
import PdBoolean from '@/modules/PersonEdit/form/PdBoolean';
import PdNumber from '@/modules/PersonEdit/form/PdNumber';
import PdEnumeration from '@/modules/PersonEdit/form/PdEnumeration';
import PdInstant from '@/modules/PersonEdit/form/PdInstant';
import PdListString from '@/modules/PersonEdit/form/PdListString';
import DfPropertyGroup from '@/components/df-property-group';

/**
 * Accepts and renders a PropertyGroup and its PropertyDefinition instances.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 */
export default {
    name: 'PropertyGroupAndPdRouter',
    components: {
        DfPropertyGroup,
        PdListString,
        PdInstant,
        PdEnumeration,
        PdNumber,
        PdString,
        PdBoolean
    },
    props: {
        propertyGroup: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters('personEdit', {
            propertyGroupUiCanBeClosed: 'propertyGroupUiCanBeClosed',
            propertyGroupUiOpened: 'propertyGroupUiOpened'
        })
    },
    methods: {
        togglePropertyGroup () {
            this.$store.commit('personEdit/togglePropertyGroupUiOpened', this.propertyGroup.name);
        }
    }
};
</script>
