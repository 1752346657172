<template>
    <div class="form-group">
        <label :for="propertyDefinition.name">{{ propertyDefinition.label }}</label>
        <draggable id="enabledBlocksList" :list="value" class="dragArea" v-bind="value">
            <div class="input-group mb-3" v-for="(item, index) in value" :key="index">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-white text-muted cursor-grab">
                        <span class="small"><i class="fas fa-grip-lines"></i></span>
                    </span>
                </div>
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': $v.value.$error }"
                       :name="propertyDefinition.name"
                       v-model.trim="value[index]"
                       :readonly="propertyDefinition.readOnly || propertyDefinition.computed"
                       autocomplete="disabled"/>
                <div class="input-group-append">
                    <span class="input-group-text bg-white text-muted cursor-pointer" @click="removeByIndex(index)">
                        <span class="small"><i class="fas fa-times"></i></span>
                    </span>
                </div>
            </div>
        </draggable>
        <div>
            <button class="btn btn-primary" @click="add">{{ $t('common_action_add') }}</button>
        </div>
        <small class="form-text text-muted" v-if="propertyDefinition.description">{{ propertyDefinition.description }}</small>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import draggable from 'vuedraggable';

/**
 * Property Definition - List of Strings
 *
 * @future implementations:
 * - invalid controls on each form element. E.g. if one item is invalid, make red.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 * @lastModifiedAt 11 July 2020
 */
export default {
    name: 'PdListString',
    mixins: [
        validationMixin
    ],
    components: {
        draggable
    },
    props: {
        propertyDefinition: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            value: null
        };
    },
    validations: {
        value: {}
    },
    beforeMount () {
        // Set for the 1st time.
        this.value = cloneDeep(this.$store.getters['personEdit/property'](this.propertyDefinition.name).workingValue);
        if (Array.isArray(this.value) === false) {
            this.value = [];
        }

        // The listen to changes and use mutations to modify property.
        // noinspection JSUnusedLocalSymbols
        this.$watch('value', {
            handler: debounce(function (ignoredValue) {
                this.$v.value.$touch();
                const newWorkingValue = cloneDeep(this.$v.value.$model)
                    .filter(function (item) {
                        if (typeof item !== 'string') return false;
                        return item.trim() !== '';
                    })
                    .filter(function (value, index, self) {
                        return self.indexOf(value) === index;
                    });
                const baseValue = this.$store.getters['personEdit/property'](this.propertyDefinition.name).baseValue;
                const changed = !isEqual(newWorkingValue, baseValue);
                this.$store.commit('personEdit/modifyProperty', {
                    name: this.propertyDefinition.name,
                    value: newWorkingValue,
                    error: this.$v.value.$error,
                    valid: !this.$v.value.$invalid,
                    changed: changed
                });
                this.$store.commit('personEdit/syncPropertiesCounts');
            }, 250),
            deep: true
        });
    },
    methods: {
        add () {
            this.value.push('');
        },
        removeByIndex (index) {
            this.value.splice(index, 1);
        }
    }
};
</script>
