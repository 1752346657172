<template>
    <div class="PersonEditDelete SweetModalWrapper--Basic">

        <!-- MODAL OPEN CONTROL -->
        <button class="btn btn-link btn-block text-danger text-left" @click="displayDeleteConfirmation">{{ $t('Delete') }}</button>

        <!-- MODAL EDITOR -->
        <sweet-modal :blocking="true"
                     :pulse-on-block="true"
                     :hide-close-button="true"
                     modal-theme="light"
                     overlay-theme="light"
                     ref="deletePersonModalRef">
            <div slot="title">
                <p class="mb-0 weight-5 size-6">{{ $t('Delete Person') }}</p>
            </div>

            <!-- VIEW LOADER -->
            <element-loading :active="deleting" :text="$t('Deleting')" :is-full-view="true" :lock-parent="true"></element-loading>

            <div class="position-relative">
                <p>{{ $t('person_delete_data_removed') }}</p>
                <ul>
                    <li>{{ $t('person_delete_data_removed_1') }}</li>
                    <li>{{ $t('person_delete_data_removed_2') }}</li>
                </ul>
                <hr/>
                <p>{{ $t('person_delete_data_remain') }}</p>
                <ul class="mb-0">
                    <li>{{ $t('person_delete_data_remain_1') }}</li>
                    <li>{{ $t('person_delete_data_remain_2') }}</li>
                    <li>{{ $t('person_delete_data_remain_3') }}</li>
                </ul>
            </div>
            <div slot="button">
                <button class="btn btn-danger" @click="submitDelete">{{ $t('Delete') }}</button>
                <button class="btn btn-link" @click="cancelDelete">{{ $t('Cancel') }}</button>
            </div>
        </sweet-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SweetModal } from 'sweet-modal-vue';
import { PersonService } from '@/common/services/api.service';

/**
 * Person delete modal and functionality.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 */
export default {
    name: 'PersonEditDelete',
    components: {
        SweetModal
    },
    data () {
        return {
            deleting: false
        };
    },
    computed: {
        ...mapGetters('personEdit', {
            person: 'person'
        })
    },
    methods: {
        displayDeleteConfirmation () {
            this.$refs.deletePersonModalRef.open();
        },
        submitDelete () {
            if (this.person.id === null) return;
            this.deleting = true;
            PersonService.deletePerson(this.person.id)
                .then(() => {
                    this.$refs.deletePersonModalRef.close();
                    this.$router.push({ name: 'people' });
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                });
        },
        cancelDelete () {
            this.$refs.deletePersonModalRef.close();
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Deleting": "Deleting",
        "Delete Person": "Delete Person",
        "Delete": "Delete",
        "Cancel": "Cancel",
        "person_delete_data_removed": "The following will be removed:",
        "person_delete_data_removed_1": "Person information",
        "person_delete_data_removed_2": "Person activities (any kind)",
        "person_delete_data_remain": "The following will remain:",
        "person_delete_data_remain_1": "Identity Profiles",
        "person_delete_data_remain_2": "Identity Profiles activities (any kind)",
        "person_delete_data_remain_3": "Identity Profiles logs and traces (any kind)"
    },
    "el": {
        "Deleting": "Γίνεται Διαγραφή",
        "Delete Person": "Διαγραφή Ατόμου",
        "Delete": "Διαγραφή",
        "Cancel": "Ακύρωση",
        "person_delete_data_removed": "Τα παρακάτω θα διαγραφούν οριστικά:",
        "person_delete_data_removed_1": "Πληροφορίες ατόμου",
        "person_delete_data_removed_2": "Δραστηριότητες ατόμου",
        "person_delete_data_remain": "Τα παρακάτω θα παραμείνουν:",
        "person_delete_data_remain_1": "Identity Profiles",
        "person_delete_data_remain_2": "Δραστηριότητες από Identity Profiles",
        "person_delete_data_remain_3": "Καταγραφές και ψηφιακά ίχνη από Identity Profiles"
    }
}
</i18n>
